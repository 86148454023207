import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { IS_STAGING } from './environment';

if (!IS_STAGING) {
  Sentry.init({
    dsn: 'https://c6f0290e10374bdca7f189d5556aae8e@o151333.ingest.sentry.io/6142385',
    release: `${
      import.meta.env.npm_package_version || import.meta.env.VERSION
    }.${import.meta.env.BUILD_NUMBER}`,
    environment: import.meta.env.ENVIRONMENT,
    tracesSampleRate: 0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
